export const environment = {
    e2e: false,
    production: false,
    firebase: {
        apiKey: "AIzaSyB97KbJQbHTlUOqdPnN2Y6WLmqiSYt81jU",
        authDomain: "dcsptest-sso.service.deloitte.ai",
        projectId: "dcsptest",
    },
    region: "europe-west1",
    brain_base_url: "https://deloitte-test.apigee.net/cwl-hux",
    authParams: ["oidc.azure"],
    signInType: 'signInWithPopup',
    aoh_url: "https://aoh-qa.assets.deloittedigital.com",
    store_url: "https://store-qa.assets.deloittedigital.com",
    api_base_url: "https://deloitte-test.apigee.net",
    hasEnterpriseAssetSearch: false,
    asset_redirect_uri: {},
    upload: {
        allowUnclassifiedFilesUpload: true,
        confidentialMetadata: [],
        existingMetadata: []
    },
    onboarding: {
        displayVideo: true,
        video_url: "https://storage.googleapis.com/dcsp-catalog-assets-/data/products/Deloitte%20AI%20Video/Deloitte%20AI%20-%20Asset%20Owner%20Hub.mp4",
        development: {
            frontend_url: "https://storage.googleapis.com/dcsp-catalog-assets-public/data/products/Frontend_Development_Kit/Deloitte.AI%20Frontend%20Development%20Kit.pdf",
            backend_url: "https://bitbucket.de.deloitte.com/projects/AI/repos/dcsp-python-framework/browse",
            business_support_email: "mailto:GlobalDeloitteAi@deloitte.com?subject=Question%20regarding%20Deloitte%20AI%20Marketplace",
        }
    },
    barrierQuestionsAbort: {
        mailAddress: "mahorst@deloitte.de"
    },
    support: {
        ccEmail: "",
        contact: "https://deloitteemea.service-now.com/mysupport?id=sc_cat_item&table=sc_cat_item&sys_id=36a2da9187688a50d1bb21bc8bbb35d7"
    },
    onboarding_options: {
        "manual": {},
        "api_passthrough": {
            authTypes: [
                { label: 'service_owner.technical.self_service.api_key', value: 'api_key' },
                { label: 'service_owner.technical.self_service.token_based_auth', value: 'bearer_token' }
            ],
            onboarding_manager: "mailto:GlobalDeloitteAi@deloitte.com?subject=API%20Onboarding%20I%20Ready%20for%20review"
        },
        "soft_onboarding": {}
    }
};
